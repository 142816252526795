





































































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import isEmpty from 'lodash/isEmpty'
import snakeCaseKeys from 'snakecase-keys'

import FavoriteStar from '@/partials/components/FavoriteStar.vue'
import TablePagination from '@/partials/tables/Pagination.vue'
import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PermissionsMixin from '@/mixins/permissionsMixin'
import EventActivitiesFavoriteTable from '@/partials/tables/Events/FavoriteActivities.vue'

@Component({
  components: {
    TablePagination,
    TrashCan16,
    Edit16,
    FavoriteStar,
    GenericModalDelete,
    EventActivitiesFavoriteTable
  }
})
export default class EventActivitiesTable extends Mixins(FlashMessageMixin, PermissionsMixin) {
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ required: true }) eventUserId!: string | false
  totalActivities = 0
  activities: Array<any> = []
  filteredData: Array<any> = []
  deleteModalIsOpened = false
  selectedActivity: any = {}
  updating = false
  paginationState: Pagination = {
    start: 0,
    page: 1,
    length: 20
  }

  fetchActivities (page = this.paginationState.page, perPage = this.paginationState.length) {
    const perPageSnake = snakeCaseKeys({ perPage })

    axios.get(`/event/${this.eventId}/activity`, {
      params: {
        'page[number]': page,
        ...perPageSnake
      }
    })
      .then(response => {
        this.activities = this.filteredData = camelCaseKeys(response.data.data, { deep: true })
        this.totalActivities = response.data.total
      })
  }

  postSubmit () {
    this.deleteModalIsOpened = false

    const flashMessage: flashMessage = {
      message: 'A Programação foi removida com sucesso.',
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetchActivities()
  }

  addToFavorites (activityId: string) {
    this.updating = true
    const submitForm = {
      eventUserId: this.eventUserId,
      eventActivityId: activityId
    }
    axios.post(`/event/${this.eventId}/favorite-activities/${this.eventUserId}`, snakeCaseKeys(submitForm))
      .then(() => {
        this.fetchActivities()
      })
      .finally(() => { this.updating = false })
  }

  deleteFavorite (favoriteId: string) {
    axios.delete(`/event/${this.eventId}/favorite-activities/${this.eventUserId}/${favoriteId}`)
      .then(() => {
        this.fetchActivities()
        this.$emit('updated')
      })
  }

  get deleteUrl () {
    return isEmpty(this.selectedActivity) ? '' : `/event/${this.eventId}/activity/${this.selectedActivity.id}`
  }

  search (searchString: string) {
    this.filteredData = this.activities.filter((activity: any) => {
      const allValues = Object.keys(activity).map(key => activity[key])

      const isMatch = allValues.some((value: string) => {
        return value.toLowerCase().includes(searchString.toLowerCase())
      })

      if (isMatch) {
        return activity
      }
    })
  }

  onPageChange (pagination: Pagination) {
    this.paginationState = pagination
    this.fetchActivities(pagination.page, pagination.length)
  }
}
