













































import { Component, Prop, Mixins } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import FavoriteStar from '@/partials/components/FavoriteStar.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    TrashCan16,
    Edit16,
    FavoriteStar,
    GenericModalDelete
  }
})
export default class EventFavoriteActivitiesTable extends Mixins(FlashMessageMixin, PermissionsMixin) {
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ required: true, type: String }) eventUserId!: string | false
  totalFavoriteActivities = 0
  favorites: Array<any> = []
  filteredData: Array<any> = []
  updating = false

  created () {
    this.fetchFavoriteActivities()
  }

  fetchFavoriteActivities () {
    axios.get(`/event/${this.eventId}/favorite-activities/${this.eventUserId}`)
      .then(response => {
        this.filteredData = camelCaseKeys(response.data.data, { deep: true })
        this.favorites = this.filteredData
      })
  }

  deleteFavorite (favoriteId: string) {
    this.updating = true
    axios.delete(`/event/${this.eventId}/favorite-activities/${this.eventUserId}/${favoriteId}`)
      .then(() => {
        this.fetchFavoriteActivities()
        this.$emit('updated')
      })
      .finally(() => { this.updating = false })
  }

  search (searchString: string) {
    this.filteredData = this.favorites.filter((activity: any) => {
      const allValues = Object.keys(activity).map(key => activity[key])

      const isMatch = allValues.some((value: string) => {
        return value.toLowerCase().includes(searchString.toLowerCase())
      })

      if (isMatch) {
        return activity
      }
    })
  }
}
