









import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import EventActivitiesTable from '@/partials/tables/Events/Activities.vue'
import EventActivitiesFavoriteTable from '@/partials/tables/Events/FavoriteActivities.vue'

const eventsPermissionsModule = namespace('eventsPermissions')

@Component({
  components: {
    EventActivitiesTable,
    EventActivitiesFavoriteTable
  }
})
export default class EventActivities extends Vue {
  @eventsPermissionsModule.Getter getEventUserId!: (eventId: string) => string
  eventUserId = ''

  created () {
    this.eventUserId = this.getEventUserId(this.$route.params.id)
  }
}
