












import { Component, Prop, Vue } from 'vue-property-decorator'
import Star16 from '@carbon/icons-vue/es/star/16'
import StarFilled16 from '@carbon/icons-vue/es/star--filled/16'

@Component({
  components: {
    Star16,
    StarFilled16
  }
})
export default class FavoriteStar extends Vue {
  @Prop({ required: true, type: Boolean }) isFavorite!: boolean
  hovering = false

  showFilled () {
    return (this.isFavorite && !this.hovering) || (!this.isFavorite && this.hovering)
  }
}
